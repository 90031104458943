.steps {
  color: $white;
  padding-block: 85px 100px;
  background-color: $primary-bg;
  position: relative;
  z-index: 0;

  &__title {
    color: $white;
  }

  &__list {
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(3, 500px);
    gap: 95px 0;
    position: relative;

    &::after {
      content: "";
      width: 0;
      height: 297px;
      border-right: 3px solid $secondary;
      border-left: none;
      position: absolute;
      left: 1500px;
      top: 39px;
      z-index: -1;
    }
  }

  &__item {
    &:nth-child(3) .steps__num::after {
      width: 420px;
    }
  }

  &__num {
    font-weight: 500;
    font-size: 40px;
    color: #fff;
    width: 80px;
    height: auto;
    aspect-ratio: 1 / 1;
    background-color: $secondary;
    border-radius: 100vw;
    display: grid;
    place-content: center;
    position: relative;

    &::after {
      content: "";
      width: 415px;
      height: 3px;
      background-color: $secondary;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
    }

    &.from-left::before,
    &.from-right::before {
      content: "";
      width: 0px;
      height: 0px;
      border-style: solid;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &.from-left::before {
      border-width: 8.5px 0 8.5px 15px;
      border-color: transparent transparent transparent $secondary;
      right: 100%;
    }

    &.from-right::before {
      border-width: 8.5px 15px 8.5px 0;
      border-color: transparent $secondary transparent transparent;
      left: 100%;
    }

    &.from-right::after {
      margin-left: 5px;
    }
  }

  &__text {
    font-weight: 500;
    max-width: 442px;
    margin-top: 20px;
  }
}

@media (max-width: 1700px) {
  .steps {
    padding-block: 60px 75px;

    &__list {
      margin-top: 60px;
      grid-template-columns: repeat(3, 350px);
      gap: 75px 0;

      &::after {
        height: 224px;
        left: 1050px;
        top: 28px;
      }
    }

    &__item {
      &:nth-child(3) .steps__num::after {
        width: 291px;
      }
    }

    &__num {
      font-size: 29px;
      width: 59px;

      &::after {
        width: 286px;
      }

      &.from-left::before {
        border-width: 6.5px 0 6.5px 11px;
      }

      &.from-right::before {
        border-width: 6.5px 11px 6.5px 0;
      }
    }

    &__text {
      max-width: 310px;
      margin-top: 15px;
    }
  }
}

@media (max-width: 1300px) {
  .steps {
    &__list {
      margin-top: 60px;
      grid-template-columns: repeat(2, 1fr);
      gap: 50px 35px;

      &::after {
        content: none;
      }
    }

    &__item {
      &:nth-child(4) {
        order: 6;
      }
      &:nth-child(5) {
        order: 5;
      }
      &:nth-child(6) {
        order: 4;
      }
    }

    &__num {
      &.from-left::before,
      &.from-right::before,
      &::after {
        content: none;
      }
    }

    &__text {
      max-width: 310px;
      margin-top: 15px;
    }
  }
}

@media (max-width: 560px) {
  .steps {
    padding-block: 50px;

    &__list {
      margin-top: 40px;
      gap: 40px 25px;
    }
  }
}
