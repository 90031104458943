.modal {
  background-color: rgba($color: #000000, $alpha: 0.4);
  display: none;
  place-content: center;
  position: fixed;
  inset: 0;

  &.open {
    display: grid;
  }

  &__window {
    background-color: $white;
    padding: 50px 100px;
    border-radius: 30px;
  }

  &__text {
    text-align: center;
  }

  &__close {
    padding: 15px 100px;
    margin: 40px auto 0;
  }
}

@media (max-width: 1700px) {
  .modal {
    &__window {
      padding: 40px 75px;
      border-radius: 24px;
    }

    &__close {
      padding: 12px 75px;
      margin-top: 30px;
    }
  }
}

@media (max-width: 560px) {
  .modal {
    &__window {
      padding: 25px 50px;
      border-radius: 18px;
    }

    &__close {
      padding: 12px 75px;
      margin-top: 30px;
    }
  }
}
