.burger-menu {
  width: 24px;
  aspect-ratio: 1 / 1;
  padding: 5px 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.bar {
  position: relative;
  width: 100%;
  height: 2px;
  background: $white;
  transition: all 0ms 300ms;
  flex-shrink: 0;

  &:after,
  &:before {
    width: 100%;
    height: 2px;
    content: "";
    position: absolute;
    left: 0;  
    background: $white;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
 
  }

  &.animate {
    background: rgba(255, 255, 255, 0);
  }

  &:before {
    bottom: 6px;
  }

  &:after {
    top: 6px;
  }

  &.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  &.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
}

