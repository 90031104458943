.footer {
  color: $white;
  padding-block: 100px 185px;
  background-color: $primary-bg;

  &__top {
    margin-bottom: 140px;
    display: grid;
    grid-template-columns: 1fr 735px;
  }

  &__caption {
    color: $white;
    margin-top: 10px;
  }

  &__text {
    margin-top: auto;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
}

@media (max-width: 1700px) {
  .footer {
    padding-block: 75px 140px;

    &__top {
      margin-bottom: 105px;
      grid-template-columns: 1fr 545px;
    }

    &__caption {
      margin-top: 8px;
    }
  }
}

@media (max-width: 1300px) {
  .footer {
    padding-block: 60px 100px;

    &__top {
      margin-bottom: 60px;
      display: flex;
      flex-direction: column;
    }

    &__caption {
      margin-block: 8px 15px;
    }

    &__text {
      max-width: 500px;
    }
  }
}

@media (max-width: 560px) {
  .footer {
    padding-block: 50px 65px;

    &__top {
      margin-bottom: 50px;
    }
  }
}
