.partnership-offer {
  .wrapper {
    padding-block: 100px;
    border-bottom: 3px solid $secondary;
  }

  &__title {
    margin-bottom: 20px;
  }
}

@media (max-width: 1700px) {
  .partnership-offer {
    .wrapper {
      padding-block: 75px;
    }

    &__title {
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 1300px) {
  .partnership-offer {
    .wrapper {
      padding-block: 65px;
    }

    &__title {
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 800px) {
  .partnership-offer {
    .wrapper {
      padding-block: 60px;
    }
  }
}

@media (max-width: 560px) {
  .partnership-offer {
    .wrapper {
      padding-block: 60px;
    }
  }
}
