.main-nav {
  display: flex;
  align-items: center;
  flex-grow: 1;

  &__link {
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    min-width: 130px;

    &:nth-child(2) {
      margin-inline: auto;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__social_link {
    color: $white;
    height: auto;
    aspect-ratio: 1 / 1;
    display: block;

    > svg {
      width: 100%;
      height: 100%;
    }

    &:hover,
    &:focus {
      color: $secondary;
    }
  }

  &__btn {
    padding: 9px 28px;
    margin-inline: 42px 35px;
  }

  &__phone {
    font-weight: 800;
    font-size: 38px;
  }
}

@media (max-width: 1700px) {
  .main-nav {
    &__link {
      font-size: 18px;
      min-width: 95px;
    }

    &__socials {
      gap: 16px;
    }

    &__social_link {
      width: 29px;
    }

    &__btn {
      padding: 6px 20px;
      margin-inline: 31px 26px;
    }

    &__phone {
      font-size: 28px;
    }
  }
}

@media (max-width: 1300px) {
  .main-nav {
    padding: 2em 1em;
    background-color: $primary-bg;
    flex-direction: column;
    align-items: end;
    gap: 20px;
    position: fixed;
    top: 73px;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1000;

    &.open {
      transform: translateX(0);
    }

    &__link {
      font-size: 24px;
      min-width: 0;

      &:nth-child(2) {
        margin-inline: 0;
      }
    }

    &__socials {
      margin-top: auto;
      gap: 16px;
    }

    &__social_link {
      width: 29px;
    }

    &__btn {
      padding: 6px 20px;
      margin-inline: 0;
    }

    &__phone {
      font-size: 28px;
    }
  }
}
