.contacts {
  &__list {
    display: flex;
    gap: 55px;
  }

  &__caption {
    font-weight: 600;
    margin-block: 28px 15px;
  }
}

@media (max-width: 1700px) {
  .contacts {
    &__list {
      gap: 40px;
    }

    &__caption {
      margin-block: 20px 10px;
    }
  }
}

@media (max-width: 1300px) {
  .contacts {
    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
    }

    &__caption {
      margin-block: 20px 10px;
    }
  }
}

@media (max-width: 560px) {
  .contacts {
    &__list {
      gap: 25px;
    }

    &__item {
      &:nth-child(1),
      &:nth-child(2) {
        grid-column: span 2;
      }
    }

    &__icon {
      width: 35px;
    }

    &__caption {
      margin-block: 12px 8px;
    }
  }
}
