.callback {
  padding-block: 195px 200px;

  &__form {
    padding: 45px 62px;
    margin-top: 100px;
    background-color: $primary-bg;
    border-radius: 31px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 95px;
  }

  &__input {
    color: $white;
    text-align: center;
    height: min-content;
    padding: 33px;
    background-color: $primary;
    border: 1px solid $white;
    border-radius: 48px;
  }

  &__submit-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 22px;
      text-align: center;
      color: #fff;
    }
  }

  &__submit {
    width: 100%;
    padding: 33px;
    margin-bottom: 28px;
  }
}

@media (max-width: 1700px) {
  .callback {
    padding-block: 145px 150px;

    &__form {
      padding: 35px 45px;
      margin-top: 75px;
      border-radius: 24px;
      gap: 70px;
    }

    &__input {
      padding: 24px;
      border-radius: 36px;
    }

    &__submit-wrap {
      p {
        font-size: 16px;
      }
    }

    &__submit {
      padding: 24px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 1300px) {
  .callback {
    padding-block: 100px 105px;

    &__form {
      padding: 30px 70px;
      margin-top: 60px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      gap: 45px;
    }

    &__input {
      padding: 24px;
      border-radius: 36px;
    }

    &__submit-wrap {
      p {
        font-size: 16px;
      }
    }

    &__submit {
      padding: 24px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 560px) {
  .callback {
    padding-block: 70px;

    &__form {
      padding: 40px 30px;
      margin-top: 40px;
      gap: 35px;
    }
  }
}
