.testimonials {
  padding-block: 125px 185px;

  &__list {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  &__item {
    padding: 33px 33px 40px;
    border: 3px solid #cdd2d0;
    border-radius: 16px;
  }

  &__author {
    font-weight: 600;
  }

  &__stars {
    margin-block: 32px 18px;
  }
}

@media (max-width: 1700px) {
  .testimonials {
    padding-block: 90px 140px;

    &__list {
      margin-top: 55px;
      gap: 35px;
    }

    &__item {
      padding: 25px 25px 30px;
      border-radius: 12px;
    }

    &__author {
      font-weight: 600;
    }

    &__stars {
      width: 126px;
      height: auto;
      margin-block: 24px 14px;
    }
  }
}

@media (max-width: 1300px) {
  .testimonials {
    padding-block: 70px 100px;

    &__list {
      margin-top: 40px;
      gap: 30px;
    }
  }
}

@media (max-width: 560px) {
  .testimonials {
    padding-block: 50px;
  }
}
