.banner {
  color: $white;
  padding-block: 147px 138px;
  background-color: $primary-bg;

  .wrapper {
    border-top: 1px solid $white;
    position: relative;
    z-index: 0;

    &::before {
      content: '';
      width: 551px;
      height: auto;
      aspect-ratio: 1 / 1;
      background: transparent url('../img/banner_bg.svg') no-repeat center / contain;
      position: absolute;
      top: 130px;
      right: -19px;
    }
  }

  &__top {
    padding-block: 100px;
    background: transparent url('../img/banner_photo.png') no-repeat right bottom;
    background-size: 892px auto;
    position: relative;
    z-index: 1;
  }

  &__title {
    max-width: 880px;
  }

  &__btn {
    padding: 20px 57px;
    margin-top: 40px;
  }

  &__bottom {
    padding-block: 70px 60px;
    margin-top: 22px;
    background-color: $primary-bg;
    border: 3px solid $primary;
    border-radius: 33px;
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: $primary;
      border: 3px solid $primary;
      border-radius: 33px;
      position: absolute;
      top: -25px;
      left: -3px;
      z-index: -1;
      box-sizing: content-box;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 482px);
    justify-content: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    + .banner__item::before {
      content: '';
      width: 3px;
      height: 155px;
      background-color: $primary;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
    }
  }

  &__icon {
    margin-block: auto;
  }

  &__text {
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    margin-top: 33px;

    span {
      font-size: 36px;
    }
  }
}

@media (max-width: 1700px) {
  .banner {
    padding-block: 110px 100px;

    .wrapper {
      &::before {
        width: 409px;
        top: 95px;
        right: -15px;
      }
    }

    &__top {
      padding-block: 73px;
      background-size: 663px auto;
      position: relative;
      z-index: 1;
    }

    &__title {
      max-width: 650px;
    }

    &__btn {
      padding: 14px 42px;
      margin-top: 30px;
    }

    &__bottom {
      padding-block: 50px 45px;
      margin-top: 20px;
      border-radius: 25px;

      &::before {
        border-radius: 25px;
        top: -23px;
        left: -3px;
      }
    }

    &__list {
      grid-template-columns: repeat(3, 358px);
    }

    &__item {
      + .banner__item::before {
        height: 115px;
      }
    }

    &__text {
      font-size: 18px;
      margin-top: 25px;

      span {
        font-size: 26px;
      }
    }
  }
}

@media (max-width: 1300px) {
  .banner {
    padding-block: 73px 80px;

    .wrapper {
      &::before {
        width: 300px;
        top: 420px;
        right: -15px;
      }
    }

    &__top {
      padding-block: 73px 373px;
    }

    &__title {
      max-width: 650px;
    }

    &__bottom {
      padding-block: 35px;
    }

    &__list {
      grid-template-columns: repeat(3, 250px);
    }

    &__item {
      + .banner__item::before {
        height: 95px;
      }
    }

    &__icon {
      width: 50px;
      height: auto;
    }

    &__text {
      font-size: 18px;
      margin-top: 25px;

      span {
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 800px) {
  .banner {
    padding-bottom: 60px;

    .wrapper {
      &::before {
        width: 250px;
        top: 495px;
        right: -15px;
      }
    }

    &__top {
      padding-block: 73px 373px;
      background-size: 100% auto;
    }

    &__title {
      max-width: 650px;
    }

    &__bottom {
      padding-block: 35px;
    }

    &__list {
      grid-template-columns: 100%;
    }

    &__item {
      padding-block: 25px;
      flex-direction: row;
      align-items: center;
      gap: 25px;

      + .banner__item::before {
        width: 80%;
        height: 3px;
        top: 0;
        left: 50%;
      }
    }

    &__icon {
      width: 50px;
      height: auto;
      margin-left: 30%;
    }

    &__text {
      text-align: left;
      margin-top: 0;
    }
  }
}

@media (max-width: 560px) {
  .banner {
    padding-bottom: 50px;

    .wrapper {
      &::before {
        width: 210px;
        top: 395px;
        right: 0;
      }
    }

    &__top {
      padding-block: 70px 340px;
      background-size: 115% auto;
    }

    &__title {
      max-width: 100%;
    }

    &__bottom {
      padding-block: 25px;
    }

    &__icon {
      margin-left: 25%;
    }
  }
}
