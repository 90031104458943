.services {
  padding-block: 95px 185px;

  &__title {
    margin-bottom: 20px;
  }

  &__approval {
    padding-bottom: 132px;
    border-bottom: 3px solid $secondary;
  }

  &__approval-list {
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(3, 500px);
    gap: 55px 50px;
  }

  &__approval-item {
    min-height: 302px;
    padding: 38px 33px;
    background-color: #f5f5f5;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  }

  &__approval-icon {
    width: 67px;
    height: auto;
    aspect-ratio: 1 / 1;
  }

  &__approval-name {
    font-weight: 500;
    margin-top: 20px;
  }

  &__trust {
    margin-top: 135px;
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 75px 60px;
  }

  &__trust-list {
    display: flex;
    flex-direction: column;
    gap: 34px;
  }

  &__trust-item {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 45px;

    &::before {
      content: '';
      width: 45px;
      height: 43px;
      background: transparent url('../img/services_icon_check.svg') no-repeat center / contain;
      flex-shrink: 0;
    }
  }

  &__trust-img {
    margin-top: 18px;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
}

@media (max-width: 1700px) {
  .services {
    padding-block: 70px 100px;

    &__title {
      margin-bottom: 16px;
    }

    &__approval {
      padding-bottom: 100px;
    }

    &__approval-list {
      margin-top: 60px;
      grid-template-columns: repeat(3, 1fr);
      gap: 35px 40px;
    }

    &__approval-item {
      min-height: 225px;
      padding: 28px 25px;
      border-radius: 12px;
    }

    &__approval-icon {
      width: 50px;
    }

    &__approval-name {
      margin-top: 14px;
    }

    &__trust {
      margin-top: 100px;
      gap: 55px 45px;
    }

    &__trust-list {
      gap: 25px;
    }

    &__trust-item {
      gap: 35px;

      &::before {
        content: '';
        width: 33px;
        height: 32px;
      }
    }

    &__trust-img {
      width: 550px;
      height: auto;
      margin-top: 12px;
    }
  }
}

@media (max-width: 1300px) {
  .services {
    &__trust {
      margin-top: 100px;
      gap: 55px 45px;
    }

    &__trust-list {
      gap: 25px;
    }

    &__trust-item {
      width: max-content;
      gap: 35px;

      &::before {
        content: '';
        width: 33px;
        height: 32px;
      }
    }

    &__trust-img {
      width: 295px;
      margin-top: 160px;
      margin-left: -170px;
    }
  }
}

@media (max-width: 800px) {
  .services {
    padding-block: 60px 80px;

    &__approval {
      padding-bottom: 80px;
    }

    &__approval-list {
      margin-top: 50px;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
    }

    &__approval-item {
      min-height: 0;
      padding: 25px;
    }

    &__approval-name {
      margin-top: 14px;
    }

    &__trust {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      gap: 35px;
    }

    &__trust-img {
      width: 100%;
      height: auto;
      margin: 0;
    }
  }
}

@media (max-width: 560px) {
  .services {
    padding-block: 50px;

    &__approval {
      padding-bottom: 50px;
    }

    &__approval-list {
      margin-top: 50px;
      grid-template-columns: 100%;
      gap: 30px;
    }

    &__approval-item {
      min-height: 0;
      padding: 25px;
    }

    &__approval-name {
      margin-top: 14px;
    }

    &__trust {
      margin-top: 50px;
    }

    &__trust-item {
      width: 100%;
    }
  }
}
