.header {
  color: $white;
  height: 147px;
  background-color: $primary-bg;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  .wrapper {
    padding: 46px 50px;
    display: flex;
    align-items: center;
  }

  &__logo {
    margin-right: 128px;
  }

  &__menu-toggle {
    display: none;
  }
}

@media (max-width: 1700px) {
  .header {
    height: 110px;

    .wrapper {
      padding: 35px;
    }

    &__logo {
      width: 140px;
      height: 32px;
      margin-right: 95px;
    }
  }
}

@media (max-width: 1300px) {
  .header {
    height: 73px;

    .wrapper {
      max-width: none;
    }

    &.modal-open::before {
      content: '';
      width: 100%;
      height: 100vh;
      background-color: rgba($color: #000000, $alpha: 0.3);
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 500;
    }

    .wrapper {
      padding: 20px;
    }

    &__logo {
      margin-right: auto;
    }

    &__menu-toggle {
      display: block;
    }
  }
}
