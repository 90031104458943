.faq {
  padding-block: 110px 130px;
  background-color: #f5f5f5;

  &__caption {
    margin-block: 10px 70px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
  }

  &__item {
    padding: 73px 33px 66px;
    background-color: $white;
    border-radius: 16px;
  }

  &__question {
    font-weight: 700;
    margin-block: 25px;
  }
}

@media (max-width: 1700px) {
  .faq {
    padding-block: 80px 95px;

    &__caption {
      margin-block: 10px 55px;
    }

    &__list {
      gap: 35px;
    }

    &__item {
      padding: 54px 24px 50px;
      border-radius: 12px;
    }

    &__icon {
      width: 75px;
      height: auto;
      aspect-ratio: 1 / 1;
    }

    &__question {
      margin-block: 20px;
    }
  }
}

@media (max-width: 1300px) {
  .faq {
    padding-block: 60px 80px;

    &__caption {
      margin-block: 10px 45px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    &__item {
      padding: 30px 25px;
      border-radius: 12px;
    }

    &__icon {
      width: 75px;
      height: auto;
      aspect-ratio: 1 / 1;
    }

    &__question {
      margin-block: 20px;
    }
  }
}

@media (max-width: 560px) {
  .faq {
    padding-block: 50px;

    &__icon {
      width: 55px;
    }
  }
}
