.partnership-promo {
  color: $white;
  padding-block: 115px 130px;
  background-color: $secondary;

  .wrapper {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: repeat(3, min-content);
    gap: 0 150px;
  }

  &__title {
    color: $white;
  }

  &__text {
    max-width: 675px;
    margin-block: 40px 60px;
  }

  &__btn {
    color: $primary;
    padding: 33px 116px 27px;
    background-color: $white;
  }

  &__img {
    margin-top: 65px;
    grid-column: 2 / 3;
    grid-row: 1 / -1;
  }
}

@media (max-width: 1700px) {
  .partnership-promo {
    padding-block: 85px 95px;

    .wrapper {
      gap: 0 110px;
    }

    &__text {
      max-width: 500px;
      margin-block: 32px 45px;
    }

    &__btn {
      padding: 25px 85px 20px;
    }

    &__img {
      width: 260px;
      height: 278px;
      margin-top: 40px;
    }
  }
}

@media (max-width: 1300px) {
  .partnership-promo {
    padding-block: 60px 80px;
    position: relative;

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    &__text {
      max-width: 500px;
      margin-block: 32px 45px;
    }

    &__btn {
      padding: 25px 85px 20px;
    }

    &__img {
      width: 200px;
      height: 218px;
      position: absolute;
      right: 5px;
      bottom: 5px;
    }
  }
}

@media (max-width: 560px) {
  .partnership-promo {
    padding-block: 50px;

    &__text {
      margin-block: 25px 70px;
    }

    &__btn {
      padding: 15px 35px 10px;
    }

    &__img {
      width: 143px;
      height: 150px;
    }
  }
}
