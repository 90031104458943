*,
*::before,
*::after {
  box-sizing: border-box;
}

*:active,
*:focus {
  outline: none;
}

html {
  min-height: 100vh;
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Fira Sans';
  font-weight: 400;
  font-style: normal;
  font-size: 28px;
  line-height: normal;
  color: $primary;
  height: 100%;
  background-color: $white;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

a,
input {
  font: inherit;
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}

a:hover {
  color: $secondary;
}

a.btn:focus {
  box-shadow: none;
}

input:focus {
  outline: none;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
}

@media (max-width: 1700px) {
  body {
    font-size: 20px;
  }
}

@media (max-width: 800px) {
  body {
    font-size: 18px;
  }
}

@media (max-width: 560px) {
  body {
    font-size: 16px;
  }
}
