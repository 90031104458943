/* breakpoints */
*,
*::before,
*::after {
  box-sizing: border-box;
}

*:active,
*:focus {
  outline: none;
}

html {
  min-height: 100vh;
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Fira Sans";
  font-weight: 400;
  font-style: normal;
  font-size: 28px;
  line-height: normal;
  color: #1a213d;
  height: 100%;
  background-color: #ffffff;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

a,
input {
  font: inherit;
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}

a:hover {
  color: #24ba6c;
}

a.btn:focus {
  box-shadow: none;
}

input:focus {
  outline: none;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
}

@media (max-width: 1700px) {
  body {
    font-size: 20px;
  }
}
@media (max-width: 800px) {
  body {
    font-size: 18px;
  }
}
@media (max-width: 560px) {
  body {
    font-size: 16px;
  }
}
.wrapper {
  max-width: 1636px;
  padding-inline: 20px;
  margin-inline: auto;
}

.btn {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #ffffff;
  width: max-content;
  background-color: #24ba6c;
  border: none;
  border-radius: 48px;
  display: grid;
  place-content: center;
}
.btn:hover {
  color: #ffffff;
  background-color: #1a213d;
}

.text-title {
  font-weight: 500;
  font-size: 38px;
  color: #24ba6c;
}

.text-caption {
  font-weight: 800;
  font-size: 70px;
  text-transform: uppercase;
}

@media (max-width: 1700px) {
  .wrapper {
    max-width: 1225px;
  }
  .btn {
    font-size: 18px;
    border-radius: 36px;
  }
  .text-title {
    font-size: 28px;
  }
  .text-caption {
    font-size: 52px;
  }
}
@media (max-width: 1300px) {
  .wrapper {
    max-width: 800px;
  }
  .text-caption {
    font-size: 45px;
  }
}
@media (max-width: 800px) {
  .wrapper {
    max-width: 540px;
  }
  .btn {
    font-size: 16px;
    border-radius: 24px;
  }
  .text-title {
    font-size: 24px;
  }
  .text-caption {
    font-size: 40px;
  }
}
@media (max-width: 560px) {
  .wrapper {
    max-width: 100%;
  }
  .btn {
    font-size: 16px;
    border-radius: 24px;
  }
  .text-title {
    font-size: 20px;
  }
  .text-caption {
    font-size: 28px;
  }
}
.header {
  color: #ffffff;
  height: 147px;
  background-color: #1f2a4e;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.header .wrapper {
  padding: 46px 50px;
  display: flex;
  align-items: center;
}
.header__logo {
  margin-right: 128px;
}
.header__menu-toggle {
  display: none;
}

@media (max-width: 1700px) {
  .header {
    height: 110px;
  }
  .header .wrapper {
    padding: 35px;
  }
  .header__logo {
    width: 140px;
    height: 32px;
    margin-right: 95px;
  }
}
@media (max-width: 1300px) {
  .header {
    height: 73px;
  }
  .header .wrapper {
    max-width: none;
  }
  .header.modal-open::before {
    content: "";
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 500;
  }
  .header .wrapper {
    padding: 20px;
  }
  .header__logo {
    margin-right: auto;
  }
  .header__menu-toggle {
    display: block;
  }
}
.burger-menu {
  width: 24px;
  aspect-ratio: 1/1;
  padding: 5px 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.bar {
  position: relative;
  width: 100%;
  height: 2px;
  background: #ffffff;
  transition: all 0ms 300ms;
  flex-shrink: 0;
}
.bar:after, .bar:before {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  left: 0;
  background: #ffffff;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.bar.animate {
  background: rgba(255, 255, 255, 0);
}
.bar:before {
  bottom: 6px;
}
.bar:after {
  top: 6px;
}
.bar.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.bar.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.main-nav {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.main-nav__link {
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  min-width: 130px;
}
.main-nav__link:nth-child(2) {
  margin-inline: auto;
}
.main-nav__socials {
  display: flex;
  align-items: center;
  gap: 20px;
}
.main-nav__social_link {
  color: #ffffff;
  height: auto;
  aspect-ratio: 1/1;
  display: block;
}
.main-nav__social_link > svg {
  width: 100%;
  height: 100%;
}
.main-nav__social_link:hover, .main-nav__social_link:focus {
  color: #24ba6c;
}
.main-nav__btn {
  padding: 9px 28px;
  margin-inline: 42px 35px;
}
.main-nav__phone {
  font-weight: 800;
  font-size: 38px;
}

@media (max-width: 1700px) {
  .main-nav__link {
    font-size: 18px;
    min-width: 95px;
  }
  .main-nav__socials {
    gap: 16px;
  }
  .main-nav__social_link {
    width: 29px;
  }
  .main-nav__btn {
    padding: 6px 20px;
    margin-inline: 31px 26px;
  }
  .main-nav__phone {
    font-size: 28px;
  }
}
@media (max-width: 1300px) {
  .main-nav {
    padding: 2em 1em;
    background-color: #1f2a4e;
    flex-direction: column;
    align-items: end;
    gap: 20px;
    position: fixed;
    top: 73px;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1000;
  }
  .main-nav.open {
    transform: translateX(0);
  }
  .main-nav__link {
    font-size: 24px;
    min-width: 0;
  }
  .main-nav__link:nth-child(2) {
    margin-inline: 0;
  }
  .main-nav__socials {
    margin-top: auto;
    gap: 16px;
  }
  .main-nav__social_link {
    width: 29px;
  }
  .main-nav__btn {
    padding: 6px 20px;
    margin-inline: 0;
  }
  .main-nav__phone {
    font-size: 28px;
  }
}
.banner {
  color: #ffffff;
  padding-block: 147px 138px;
  background-color: #1f2a4e;
}
.banner .wrapper {
  border-top: 1px solid #ffffff;
  position: relative;
  z-index: 0;
}
.banner .wrapper::before {
  content: "";
  width: 551px;
  height: auto;
  aspect-ratio: 1/1;
  background: transparent url("../img/banner_bg.svg") no-repeat center/contain;
  position: absolute;
  top: 130px;
  right: -19px;
}
.banner__top {
  padding-block: 100px;
  background: transparent url("../img/banner_photo.png") no-repeat right bottom;
  background-size: 892px auto;
  position: relative;
  z-index: 1;
}
.banner__title {
  max-width: 880px;
}
.banner__btn {
  padding: 20px 57px;
  margin-top: 40px;
}
.banner__bottom {
  padding-block: 70px 60px;
  margin-top: 22px;
  background-color: #1f2a4e;
  border: 3px solid #1a213d;
  border-radius: 33px;
  position: relative;
}
.banner__bottom::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #1a213d;
  border: 3px solid #1a213d;
  border-radius: 33px;
  position: absolute;
  top: -25px;
  left: -3px;
  z-index: -1;
  box-sizing: content-box;
}
.banner__list {
  display: grid;
  grid-template-columns: repeat(3, 482px);
  justify-content: center;
}
.banner__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.banner__item + .banner__item::before {
  content: "";
  width: 3px;
  height: 155px;
  background-color: #1a213d;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}
.banner__icon {
  margin-block: auto;
}
.banner__text {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  margin-top: 33px;
}
.banner__text span {
  font-size: 36px;
}

@media (max-width: 1700px) {
  .banner {
    padding-block: 110px 100px;
  }
  .banner .wrapper::before {
    width: 409px;
    top: 95px;
    right: -15px;
  }
  .banner__top {
    padding-block: 73px;
    background-size: 663px auto;
    position: relative;
    z-index: 1;
  }
  .banner__title {
    max-width: 650px;
  }
  .banner__btn {
    padding: 14px 42px;
    margin-top: 30px;
  }
  .banner__bottom {
    padding-block: 50px 45px;
    margin-top: 20px;
    border-radius: 25px;
  }
  .banner__bottom::before {
    border-radius: 25px;
    top: -23px;
    left: -3px;
  }
  .banner__list {
    grid-template-columns: repeat(3, 358px);
  }
  .banner__item + .banner__item::before {
    height: 115px;
  }
  .banner__text {
    font-size: 18px;
    margin-top: 25px;
  }
  .banner__text span {
    font-size: 26px;
  }
}
@media (max-width: 1300px) {
  .banner {
    padding-block: 73px 80px;
  }
  .banner .wrapper::before {
    width: 300px;
    top: 420px;
    right: -15px;
  }
  .banner__top {
    padding-block: 73px 373px;
  }
  .banner__title {
    max-width: 650px;
  }
  .banner__bottom {
    padding-block: 35px;
  }
  .banner__list {
    grid-template-columns: repeat(3, 250px);
  }
  .banner__item + .banner__item::before {
    height: 95px;
  }
  .banner__icon {
    width: 50px;
    height: auto;
  }
  .banner__text {
    font-size: 18px;
    margin-top: 25px;
  }
  .banner__text span {
    font-size: 24px;
  }
}
@media (max-width: 800px) {
  .banner {
    padding-bottom: 60px;
  }
  .banner .wrapper::before {
    width: 250px;
    top: 495px;
    right: -15px;
  }
  .banner__top {
    padding-block: 73px 373px;
    background-size: 100% auto;
  }
  .banner__title {
    max-width: 650px;
  }
  .banner__bottom {
    padding-block: 35px;
  }
  .banner__list {
    grid-template-columns: 100%;
  }
  .banner__item {
    padding-block: 25px;
    flex-direction: row;
    align-items: center;
    gap: 25px;
  }
  .banner__item + .banner__item::before {
    width: 80%;
    height: 3px;
    top: 0;
    left: 50%;
  }
  .banner__icon {
    width: 50px;
    height: auto;
    margin-left: 30%;
  }
  .banner__text {
    text-align: left;
    margin-top: 0;
  }
}
@media (max-width: 560px) {
  .banner {
    padding-bottom: 50px;
  }
  .banner .wrapper::before {
    width: 210px;
    top: 395px;
    right: 0;
  }
  .banner__top {
    padding-block: 70px 340px;
    background-size: 115% auto;
  }
  .banner__title {
    max-width: 100%;
  }
  .banner__bottom {
    padding-block: 25px;
  }
  .banner__icon {
    margin-left: 25%;
  }
}
.services {
  padding-block: 95px 185px;
}
.services__title {
  margin-bottom: 20px;
}
.services__approval {
  padding-bottom: 132px;
  border-bottom: 3px solid #24ba6c;
}
.services__approval-list {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(3, 500px);
  gap: 55px 50px;
}
.services__approval-item {
  min-height: 302px;
  padding: 38px 33px;
  background-color: #f5f5f5;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}
.services__approval-icon {
  width: 67px;
  height: auto;
  aspect-ratio: 1/1;
}
.services__approval-name {
  font-weight: 500;
  margin-top: 20px;
}
.services__trust {
  margin-top: 135px;
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 75px 60px;
}
.services__trust-list {
  display: flex;
  flex-direction: column;
  gap: 34px;
}
.services__trust-item {
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 45px;
}
.services__trust-item::before {
  content: "";
  width: 45px;
  height: 43px;
  background: transparent url("../img/services_icon_check.svg") no-repeat center/contain;
  flex-shrink: 0;
}
.services__trust-img {
  margin-top: 18px;
  grid-column: 2/3;
  grid-row: 1/3;
}

@media (max-width: 1700px) {
  .services {
    padding-block: 70px 100px;
  }
  .services__title {
    margin-bottom: 16px;
  }
  .services__approval {
    padding-bottom: 100px;
  }
  .services__approval-list {
    margin-top: 60px;
    grid-template-columns: repeat(3, 1fr);
    gap: 35px 40px;
  }
  .services__approval-item {
    min-height: 225px;
    padding: 28px 25px;
    border-radius: 12px;
  }
  .services__approval-icon {
    width: 50px;
  }
  .services__approval-name {
    margin-top: 14px;
  }
  .services__trust {
    margin-top: 100px;
    gap: 55px 45px;
  }
  .services__trust-list {
    gap: 25px;
  }
  .services__trust-item {
    gap: 35px;
  }
  .services__trust-item::before {
    content: "";
    width: 33px;
    height: 32px;
  }
  .services__trust-img {
    width: 550px;
    height: auto;
    margin-top: 12px;
  }
}
@media (max-width: 1300px) {
  .services__trust {
    margin-top: 100px;
    gap: 55px 45px;
  }
  .services__trust-list {
    gap: 25px;
  }
  .services__trust-item {
    width: max-content;
    gap: 35px;
  }
  .services__trust-item::before {
    content: "";
    width: 33px;
    height: 32px;
  }
  .services__trust-img {
    width: 295px;
    margin-top: 160px;
    margin-left: -170px;
  }
}
@media (max-width: 800px) {
  .services {
    padding-block: 60px 80px;
  }
  .services__approval {
    padding-bottom: 80px;
  }
  .services__approval-list {
    margin-top: 50px;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
  .services__approval-item {
    min-height: 0;
    padding: 25px;
  }
  .services__approval-name {
    margin-top: 14px;
  }
  .services__trust {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
  .services__trust-img {
    width: 100%;
    height: auto;
    margin: 0;
  }
}
@media (max-width: 560px) {
  .services {
    padding-block: 50px;
  }
  .services__approval {
    padding-bottom: 50px;
  }
  .services__approval-list {
    margin-top: 50px;
    grid-template-columns: 100%;
    gap: 30px;
  }
  .services__approval-item {
    min-height: 0;
    padding: 25px;
  }
  .services__approval-name {
    margin-top: 14px;
  }
  .services__trust {
    margin-top: 50px;
  }
  .services__trust-item {
    width: 100%;
  }
}
.steps {
  color: #ffffff;
  padding-block: 85px 100px;
  background-color: #1f2a4e;
  position: relative;
  z-index: 0;
}
.steps__title {
  color: #ffffff;
}
.steps__list {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(3, 500px);
  gap: 95px 0;
  position: relative;
}
.steps__list::after {
  content: "";
  width: 0;
  height: 297px;
  border-right: 3px solid #24ba6c;
  border-left: none;
  position: absolute;
  left: 1500px;
  top: 39px;
  z-index: -1;
}
.steps__item:nth-child(3) .steps__num::after {
  width: 420px;
}
.steps__num {
  font-weight: 500;
  font-size: 40px;
  color: #fff;
  width: 80px;
  height: auto;
  aspect-ratio: 1/1;
  background-color: #24ba6c;
  border-radius: 100vw;
  display: grid;
  place-content: center;
  position: relative;
}
.steps__num::after {
  content: "";
  width: 415px;
  height: 3px;
  background-color: #24ba6c;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.steps__num.from-left::before, .steps__num.from-right::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.steps__num.from-left::before {
  border-width: 8.5px 0 8.5px 15px;
  border-color: transparent transparent transparent #24ba6c;
  right: 100%;
}
.steps__num.from-right::before {
  border-width: 8.5px 15px 8.5px 0;
  border-color: transparent #24ba6c transparent transparent;
  left: 100%;
}
.steps__num.from-right::after {
  margin-left: 5px;
}
.steps__text {
  font-weight: 500;
  max-width: 442px;
  margin-top: 20px;
}

@media (max-width: 1700px) {
  .steps {
    padding-block: 60px 75px;
  }
  .steps__list {
    margin-top: 60px;
    grid-template-columns: repeat(3, 350px);
    gap: 75px 0;
  }
  .steps__list::after {
    height: 224px;
    left: 1050px;
    top: 28px;
  }
  .steps__item:nth-child(3) .steps__num::after {
    width: 291px;
  }
  .steps__num {
    font-size: 29px;
    width: 59px;
  }
  .steps__num::after {
    width: 286px;
  }
  .steps__num.from-left::before {
    border-width: 6.5px 0 6.5px 11px;
  }
  .steps__num.from-right::before {
    border-width: 6.5px 11px 6.5px 0;
  }
  .steps__text {
    max-width: 310px;
    margin-top: 15px;
  }
}
@media (max-width: 1300px) {
  .steps__list {
    margin-top: 60px;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px 35px;
  }
  .steps__list::after {
    content: none;
  }
  .steps__item:nth-child(4) {
    order: 6;
  }
  .steps__item:nth-child(5) {
    order: 5;
  }
  .steps__item:nth-child(6) {
    order: 4;
  }
  .steps__num.from-left::before, .steps__num.from-right::before, .steps__num::after {
    content: none;
  }
  .steps__text {
    max-width: 310px;
    margin-top: 15px;
  }
}
@media (max-width: 560px) {
  .steps {
    padding-block: 50px;
  }
  .steps__list {
    margin-top: 40px;
    gap: 40px 25px;
  }
}
.banks {
  padding-block: 140px;
}
.banks__title {
  max-width: 1160px;
}
.banks__slider-wrap {
  margin-top: 100px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.banks__slider {
  position: relative;
  width: 1450px;
  padding: 40px 50px;
  margin-inline: auto;
  margin-bottom: 0 !important;
  border: 3px solid #24ba6c;
  border-radius: 129px;
}
.banks .slick-track {
  display: flex;
  align-items: center;
}
.banks__item.slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.banks .slick-dots {
  width: 100%;
  margin-top: 35px;
  position: static;
}
.banks .slick-dots li {
  margin-inline: 7.5px;
}
.banks .slick-dots button::before {
  content: "";
  background-color: #cdd2d0;
  border-radius: 100vw;
  opacity: 1;
}
.banks .slick-dots .slick-active button::before {
  background-color: #24ba6c;
  opacity: 1;
}

@media (max-width: 1700px) {
  .banks {
    padding-block: 105px;
  }
  .banks__title {
    max-width: 860px;
  }
  .banks__slider-wrap {
    margin-top: 75px;
  }
  .banks__slider {
    width: 1075px;
    padding: 30px 40px;
    border-radius: 96px;
  }
  .banks__img {
    width: auto;
    max-width: 185px;
    height: auto;
    max-height: 100px;
    object-fit: contain;
  }
  .banks .slick-dots {
    margin-top: 28px;
  }
  .banks .slick-dots li {
    margin-inline: 5px;
  }
}
@media (max-width: 1300px) {
  .banks {
    padding-block: 85px;
  }
  .banks__slider-wrap {
    margin-top: 60px;
  }
  .banks__slider {
    width: 650px;
    padding: 15px 25px;
    border-radius: 80px;
  }
  .banks__img {
    width: auto;
    max-width: 185px;
    height: auto;
    max-height: 100px;
    object-fit: contain;
  }
  .banks .slick-dots {
    margin-top: 28px;
  }
  .banks .slick-dots li {
    margin-inline: 5px;
  }
}
@media (max-width: 800px) {
  .banks {
    padding-block: 60px 80px;
  }
  .banks__slider-wrap {
    margin-top: 50px;
  }
  .banks__slider {
    width: 400px;
    padding: 15px;
    border-radius: 60px;
  }
  .banks .slick-dots {
    margin-top: 20px;
  }
}
@media (max-width: 560px) {
  .banks {
    padding-block: 50px;
  }
  .banks__slider-wrap {
    margin-top: 50px;
  }
  .banks__slider {
    width: 100%;
    padding: 15px;
    border-radius: 40px;
  }
  .banks__nav {
    display: none !important;
  }
  .banks__img {
    max-width: 120px;
    max-height: 70px;
  }
  .banks .slick-dots {
    margin-top: 20px;
  }
}
.certificates {
  padding-block: 100px 165px;
  background-color: #f5f5f5;
}
.certificates__list {
  width: max-content;
  max-width: 100%;
  margin-top: 90px;
  margin-inline: auto;
  display: flex;
  gap: 30px;
}
.certificates__link {
  width: auto;
  height: 328px;
  border: 3px solid #1a213d;
  border-radius: 12px;
  display: block;
  overflow: hidden;
}
.certificates__link:hover, .certificates__link:focus {
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.25);
}
.certificates__link > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1700px) {
  .certificates {
    padding-block: 75px 120px;
  }
  .certificates__list {
    margin-top: 60px;
    gap: 24px;
  }
  .certificates__link {
    height: 240px;
  }
}
@media (max-width: 1300px) {
  .certificates {
    padding-block: 75px 100px;
  }
  .certificates__list {
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .certificates__link {
    height: 240px;
    min-width: 200px;
  }
}
@media (max-width: 800px) {
  .certificates {
    padding-block: 60px 80px;
  }
  .certificates__list {
    margin-top: 40px;
    gap: 15px;
  }
  .certificates__link {
    height: 200px;
    min-width: 150px;
  }
}
@media (max-width: 560px) {
  .certificates {
    padding-block: 50px;
  }
  .certificates__list {
    margin-top: 30px;
    gap: 15px;
  }
  .certificates__link {
    height: 180px;
    min-width: 0;
  }
}
.testimonials {
  padding-block: 125px 185px;
}
.testimonials__list {
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.testimonials__item {
  padding: 33px 33px 40px;
  border: 3px solid #cdd2d0;
  border-radius: 16px;
}
.testimonials__author {
  font-weight: 600;
}
.testimonials__stars {
  margin-block: 32px 18px;
}

@media (max-width: 1700px) {
  .testimonials {
    padding-block: 90px 140px;
  }
  .testimonials__list {
    margin-top: 55px;
    gap: 35px;
  }
  .testimonials__item {
    padding: 25px 25px 30px;
    border-radius: 12px;
  }
  .testimonials__author {
    font-weight: 600;
  }
  .testimonials__stars {
    width: 126px;
    height: auto;
    margin-block: 24px 14px;
  }
}
@media (max-width: 1300px) {
  .testimonials {
    padding-block: 70px 100px;
  }
  .testimonials__list {
    margin-top: 40px;
    gap: 30px;
  }
}
@media (max-width: 560px) {
  .testimonials {
    padding-block: 50px;
  }
}
.faq {
  padding-block: 110px 130px;
  background-color: #f5f5f5;
}
.faq__caption {
  margin-block: 10px 70px;
}
.faq__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}
.faq__item {
  padding: 73px 33px 66px;
  background-color: #ffffff;
  border-radius: 16px;
}
.faq__question {
  font-weight: 700;
  margin-block: 25px;
}

@media (max-width: 1700px) {
  .faq {
    padding-block: 80px 95px;
  }
  .faq__caption {
    margin-block: 10px 55px;
  }
  .faq__list {
    gap: 35px;
  }
  .faq__item {
    padding: 54px 24px 50px;
    border-radius: 12px;
  }
  .faq__icon {
    width: 75px;
    height: auto;
    aspect-ratio: 1/1;
  }
  .faq__question {
    margin-block: 20px;
  }
}
@media (max-width: 1300px) {
  .faq {
    padding-block: 60px 80px;
  }
  .faq__caption {
    margin-block: 10px 45px;
  }
  .faq__list {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .faq__item {
    padding: 30px 25px;
    border-radius: 12px;
  }
  .faq__icon {
    width: 75px;
    height: auto;
    aspect-ratio: 1/1;
  }
  .faq__question {
    margin-block: 20px;
  }
}
@media (max-width: 560px) {
  .faq {
    padding-block: 50px;
  }
  .faq__icon {
    width: 55px;
  }
}
.partnership-promo {
  color: #ffffff;
  padding-block: 115px 130px;
  background-color: #24ba6c;
}
.partnership-promo .wrapper {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: repeat(3, min-content);
  gap: 0 150px;
}
.partnership-promo__title {
  color: #ffffff;
}
.partnership-promo__text {
  max-width: 675px;
  margin-block: 40px 60px;
}
.partnership-promo__btn {
  color: #1a213d;
  padding: 33px 116px 27px;
  background-color: #ffffff;
}
.partnership-promo__img {
  margin-top: 65px;
  grid-column: 2/3;
  grid-row: 1/-1;
}

@media (max-width: 1700px) {
  .partnership-promo {
    padding-block: 85px 95px;
  }
  .partnership-promo .wrapper {
    gap: 0 110px;
  }
  .partnership-promo__text {
    max-width: 500px;
    margin-block: 32px 45px;
  }
  .partnership-promo__btn {
    padding: 25px 85px 20px;
  }
  .partnership-promo__img {
    width: 260px;
    height: 278px;
    margin-top: 40px;
  }
}
@media (max-width: 1300px) {
  .partnership-promo {
    padding-block: 60px 80px;
    position: relative;
  }
  .partnership-promo .wrapper {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .partnership-promo__text {
    max-width: 500px;
    margin-block: 32px 45px;
  }
  .partnership-promo__btn {
    padding: 25px 85px 20px;
  }
  .partnership-promo__img {
    width: 200px;
    height: 218px;
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
}
@media (max-width: 560px) {
  .partnership-promo {
    padding-block: 50px;
  }
  .partnership-promo__text {
    margin-block: 25px 70px;
  }
  .partnership-promo__btn {
    padding: 15px 35px 10px;
  }
  .partnership-promo__img {
    width: 143px;
    height: 150px;
  }
}
.callback {
  padding-block: 195px 200px;
}
.callback__form {
  padding: 45px 62px;
  margin-top: 100px;
  background-color: #1f2a4e;
  border-radius: 31px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 95px;
}
.callback__input {
  color: #ffffff;
  text-align: center;
  height: min-content;
  padding: 33px;
  background-color: #1a213d;
  border: 1px solid #ffffff;
  border-radius: 48px;
}
.callback__submit-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.callback__submit-wrap p {
  font-size: 22px;
  text-align: center;
  color: #fff;
}
.callback__submit {
  width: 100%;
  padding: 33px;
  margin-bottom: 28px;
}

@media (max-width: 1700px) {
  .callback {
    padding-block: 145px 150px;
  }
  .callback__form {
    padding: 35px 45px;
    margin-top: 75px;
    border-radius: 24px;
    gap: 70px;
  }
  .callback__input {
    padding: 24px;
    border-radius: 36px;
  }
  .callback__submit-wrap p {
    font-size: 16px;
  }
  .callback__submit {
    padding: 24px;
    margin-bottom: 20px;
  }
}
@media (max-width: 1300px) {
  .callback {
    padding-block: 100px 105px;
  }
  .callback__form {
    padding: 30px 70px;
    margin-top: 60px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 45px;
  }
  .callback__input {
    padding: 24px;
    border-radius: 36px;
  }
  .callback__submit-wrap p {
    font-size: 16px;
  }
  .callback__submit {
    padding: 24px;
    margin-bottom: 20px;
  }
}
@media (max-width: 560px) {
  .callback {
    padding-block: 70px;
  }
  .callback__form {
    padding: 40px 30px;
    margin-top: 40px;
    gap: 35px;
  }
}
.footer {
  color: #ffffff;
  padding-block: 100px 185px;
  background-color: #1f2a4e;
}
.footer__top {
  margin-bottom: 140px;
  display: grid;
  grid-template-columns: 1fr 735px;
}
.footer__caption {
  color: #ffffff;
  margin-top: 10px;
}
.footer__text {
  margin-top: auto;
  grid-column: 2/3;
  grid-row: 1/3;
}

@media (max-width: 1700px) {
  .footer {
    padding-block: 75px 140px;
  }
  .footer__top {
    margin-bottom: 105px;
    grid-template-columns: 1fr 545px;
  }
  .footer__caption {
    margin-top: 8px;
  }
}
@media (max-width: 1300px) {
  .footer {
    padding-block: 60px 100px;
  }
  .footer__top {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
  }
  .footer__caption {
    margin-block: 8px 15px;
  }
  .footer__text {
    max-width: 500px;
  }
}
@media (max-width: 560px) {
  .footer {
    padding-block: 50px 65px;
  }
  .footer__top {
    margin-bottom: 50px;
  }
}
.contacts__list {
  display: flex;
  gap: 55px;
}
.contacts__caption {
  font-weight: 600;
  margin-block: 28px 15px;
}

@media (max-width: 1700px) {
  .contacts__list {
    gap: 40px;
  }
  .contacts__caption {
    margin-block: 20px 10px;
  }
}
@media (max-width: 1300px) {
  .contacts__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
  .contacts__caption {
    margin-block: 20px 10px;
  }
}
@media (max-width: 560px) {
  .contacts__list {
    gap: 25px;
  }
  .contacts__item:nth-child(1), .contacts__item:nth-child(2) {
    grid-column: span 2;
  }
  .contacts__icon {
    width: 35px;
  }
  .contacts__caption {
    margin-block: 12px 8px;
  }
}
.partnership-banner {
  color: #ffffff;
  padding-block: 100px;
  background-color: #1f2a4e;
}
.partnership-banner .wrapper {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr min-content;
}
.partnership-banner__title {
  max-width: 880px;
}
.partnership-banner__btn {
  padding: 20px 57px;
  margin-top: 40px;
}
.partnership-banner__img {
  width: 428px;
  height: auto;
  aspect-ratio: 1/1;
  grid-column: 2/3;
  grid-row: 1/3;
}

@media (max-width: 1700px) {
  .partnership-banner {
    padding-block: 75px;
  }
  .partnership-banner__title {
    max-width: 650px;
  }
  .partnership-banner__btn {
    padding: 14px 42px;
    margin-top: 30px;
  }
  .partnership-banner__img {
    width: 310px;
  }
}
@media (max-width: 1300px) {
  .partnership-banner {
    padding-block: 65px;
  }
  .partnership-banner__title {
    max-width: 650px;
  }
  .partnership-banner__img {
    width: 250px;
  }
}
@media (max-width: 800px) {
  .partnership-banner {
    padding-bottom: 60px;
  }
  .partnership-banner .wrapper {
    grid-template-columns: 100%;
  }
  .partnership-banner__top {
    padding-block: 73px 373px;
    background-size: 100% auto;
  }
  .partnership-banner__img {
    width: 100%;
    max-width: 310px;
    margin: 30px auto 0;
    grid-column: auto;
    grid-row: auto;
  }
}
@media (max-width: 560px) {
  .partnership-banner {
    padding-block: 50px;
  }
  .partnership-banner__title {
    max-width: 100%;
  }
}
.partnership-offer .wrapper {
  padding-block: 100px;
  border-bottom: 3px solid #24ba6c;
}
.partnership-offer__title {
  margin-bottom: 20px;
}

@media (max-width: 1700px) {
  .partnership-offer .wrapper {
    padding-block: 75px;
  }
  .partnership-offer__title {
    margin-bottom: 16px;
  }
}
@media (max-width: 1300px) {
  .partnership-offer .wrapper {
    padding-block: 65px;
  }
  .partnership-offer__title {
    margin-bottom: 16px;
  }
}
@media (max-width: 800px) {
  .partnership-offer .wrapper {
    padding-block: 60px;
  }
}
@media (max-width: 560px) {
  .partnership-offer .wrapper {
    padding-block: 60px;
  }
}
.partnership-about {
  position: relative;
  z-index: 0;
}
.partnership-about .wrapper {
  padding-block: 100px;
  border-bottom: 3px solid #24ba6c;
}
.partnership-about__list {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(3, 500px);
  gap: 95px 0;
  position: relative;
}
.partnership-about__list::after {
  content: "";
  width: 0;
  height: 396px;
  border-right: 3px solid #24ba6c;
  border-left: none;
  position: absolute;
  left: 1500px;
  top: 39px;
  z-index: -1;
}
.partnership-about__item:nth-child(3) .partnership-about__icon::after {
  width: 420px;
}
.partnership-about__icon {
  width: 80px;
  height: auto;
  aspect-ratio: 1/1;
  display: grid;
  place-content: center;
  position: relative;
}
.partnership-about__icon > img {
  width: 65px;
  height: auto;
  aspect-ratio: 1/1;
}
.partnership-about__icon::after {
  content: "";
  width: 415px;
  height: 3px;
  background-color: #24ba6c;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.partnership-about__icon.from-left::before, .partnership-about__icon.from-right::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.partnership-about__icon.from-left::before {
  border-width: 8.5px 0 8.5px 15px;
  border-color: transparent transparent transparent #24ba6c;
  right: 100%;
}
.partnership-about__icon.from-right::before {
  border-width: 8.5px 15px 8.5px 0;
  border-color: transparent #24ba6c transparent transparent;
  left: 100%;
}
.partnership-about__icon.from-right::after {
  margin-left: 5px;
}
.partnership-about__text {
  font-weight: 500;
  max-width: 442px;
  margin-top: 20px;
}

@media (max-width: 1700px) {
  .partnership-about .wrapper {
    padding-block: 75px;
  }
  .partnership-about__list {
    margin-top: 60px;
    grid-template-columns: repeat(3, 350px);
    gap: 75px 0;
  }
  .partnership-about__list::after {
    height: 319px;
    left: 1050px;
    top: 28px;
  }
  .partnership-about__item:nth-child(3) .partnership-about__icon::after {
    width: 291px;
  }
  .partnership-about__icon {
    width: 59px;
  }
  .partnership-about__icon > img {
    width: 50px;
  }
  .partnership-about__icon::after {
    width: 286px;
  }
  .partnership-about__icon.from-left::before {
    border-width: 6.5px 0 6.5px 11px;
  }
  .partnership-about__icon.from-right::before {
    border-width: 6.5px 11px 6.5px 0;
  }
  .partnership-about__text {
    max-width: 310px;
    margin-top: 15px;
  }
}
@media (max-width: 1300px) {
  .partnership-about .wrapper {
    padding-block: 65px;
  }
  .partnership-about__list {
    margin-top: 60px;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px 35px;
  }
  .partnership-about__list::after {
    content: none;
  }
  .partnership-about__item:nth-child(4) {
    order: 6;
  }
  .partnership-about__item:nth-child(5) {
    order: 5;
  }
  .partnership-about__item:nth-child(6) {
    order: 4;
  }
  .partnership-about__icon.from-left::before, .partnership-about__icon.from-right::before, .partnership-about__icon::after {
    content: none;
  }
  .partnership-about__text {
    max-width: 310px;
    margin-top: 15px;
  }
}
@media (max-width: 560px) {
  .partnership-about .wrapper {
    padding-block: 50px;
  }
  .partnership-about__list {
    margin-top: 40px;
    gap: 40px 25px;
  }
}
.modal {
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  place-content: center;
  position: fixed;
  inset: 0;
}
.modal.open {
  display: grid;
}
.modal__window {
  background-color: #ffffff;
  padding: 50px 100px;
  border-radius: 30px;
}
.modal__text {
  text-align: center;
}
.modal__close {
  padding: 15px 100px;
  margin: 40px auto 0;
}

@media (max-width: 1700px) {
  .modal__window {
    padding: 40px 75px;
    border-radius: 24px;
  }
  .modal__close {
    padding: 12px 75px;
    margin-top: 30px;
  }
}
@media (max-width: 560px) {
  .modal__window {
    padding: 25px 50px;
    border-radius: 18px;
  }
  .modal__close {
    padding: 12px 75px;
    margin-top: 30px;
  }
}