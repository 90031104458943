.partnership-banner {
  color: $white;
  padding-block: 100px;
  background-color: $primary-bg;

  .wrapper {
    position: relative;
    z-index: 0;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: 1fr min-content;
  }

  &__title {
    max-width: 880px;
  }

  &__btn {
    padding: 20px 57px;
    margin-top: 40px;
  }

  &__img {
    width: 428px;
    height: auto;
    aspect-ratio: 1 / 1;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
}

@media (max-width: 1700px) {
  .partnership-banner {
    padding-block: 75px;

    &__title {
      max-width: 650px;
    }

    &__btn {
      padding: 14px 42px;
      margin-top: 30px;
    }

    &__img {
      width: 310px;
    }
  }
}

@media (max-width: 1300px) {
  .partnership-banner {
    padding-block: 65px;

    &__title {
      max-width: 650px;
    }

    &__img {
      width: 250px;
    }
  }
}

@media (max-width: 800px) {
  .partnership-banner {
    padding-bottom: 60px;

    .wrapper {
      grid-template-columns: 100%;
    }

    &__top {
      padding-block: 73px 373px;
      background-size: 100% auto;
    }

    &__img {
      width: 100%;
      max-width: 310px;
      margin: 30px auto 0;
      grid-column: auto;
      grid-row: auto;
    }
  }
}

@media (max-width: 560px) {
  .partnership-banner {
    padding-block: 50px;

    &__title {
      max-width: 100%;
    }
  }
}
