.certificates {
  padding-block: 100px 165px;
  background-color: #f5f5f5;

  &__list {
    width: max-content;
    max-width: 100%;
    margin-top: 90px;
    margin-inline: auto;
    display: flex;
    gap: 30px;
  }

  &__link {
    width: auto;
    height: 328px;
    border: 3px solid $primary;
    border-radius: 12px;
    display: block;
    overflow: hidden;

    &:hover,
    &:focus {
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.25);
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (max-width: 1700px) {
  .certificates {
    padding-block: 75px 120px;

    &__list {
      margin-top: 60px;
      gap: 24px;
    }

    &__link {
      height: 240px;
    }
  }
}

@media (max-width: 1300px) {
  .certificates {
    padding-block: 75px 100px;

    &__list {
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__link {
      height: 240px;
      min-width: 200px;
    }
  }
}

@media (max-width: 800px) {
  .certificates {
    padding-block: 60px 80px;

    &__list {
      margin-top: 40px;
      gap: 15px;
    }

    &__link {
      height: 200px;
      min-width: 150px;
    }
  }
}

@media (max-width: 560px) {
  .certificates {
    padding-block: 50px;

    &__list {
      margin-top: 30px;
      gap: 15px;
    }

    &__link {
      height: 180px;
      min-width: 0;
    }
  }
}
