.banks {
  padding-block: 140px;

  &__title {
    max-width: 1160px;
  }

  &__slider-wrap {
    margin-top: 100px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__slider {
    position: relative;
    width: 1450px;
    padding: 40px 50px;
    margin-inline: auto;
    margin-bottom: 0 !important;
    border: 3px solid $secondary;
    border-radius: 129px;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  &__item.slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-dots {
    width: 100%;
    margin-top: 35px;
    position: static;
  }

  .slick-dots li {
    margin-inline: 7.5px;
  }

  .slick-dots button {
    &::before {
      content: "";
      background-color: #cdd2d0;
      border-radius: 100vw;
      opacity: 1;
    }
  }

  .slick-dots .slick-active button {
    &::before {
      background-color: $secondary;
      opacity: 1;
    }
  }
}

@media (max-width: 1700px) {
  .banks {
    padding-block: 105px;

    &__title {
      max-width: 860px;
    }

    &__slider-wrap {
      margin-top: 75px;
    }

    &__slider {
      width: 1075px;
      padding: 30px 40px;
      border-radius: 96px;
    }

    &__img {
      width: auto;
      max-width: 185px;
      height: auto;
      max-height: 100px;
      object-fit: contain;
    }

    .slick-dots {
      margin-top: 28px;
    }

    .slick-dots li {
      margin-inline: 5px;
    }
  }
}

@media (max-width: 1300px) {
  .banks {
    padding-block: 85px;

    &__slider-wrap {
      margin-top: 60px;
    }

    &__slider {
      width: 650px;
      padding: 15px 25px;
      border-radius: 80px;
    }

    &__img {
      width: auto;
      max-width: 185px;
      height: auto;
      max-height: 100px;
      object-fit: contain;
    }

    .slick-dots {
      margin-top: 28px;
    }

    .slick-dots li {
      margin-inline: 5px;
    }
  }
}

@media (max-width: 800px) {
  .banks {
    padding-block: 60px 80px;

    &__slider-wrap {
      margin-top: 50px;
    }

    &__slider {
      width: 400px;
      padding: 15px;
      border-radius: 60px;
    }

    .slick-dots {
      margin-top: 20px;
    }
  }
}

@media (max-width: 560px) {
  .banks {
    padding-block: 50px;

    &__slider-wrap {
      margin-top: 50px;
    }

    &__slider {
      width: 100%;
      padding: 15px;
      border-radius: 40px;
    }

    &__nav {
      display: none !important;
    }

    &__img {
      max-width: 120px;
      max-height: 70px;
    }

    .slick-dots {
      margin-top: 20px;
    }
  }
}
